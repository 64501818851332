<template>
  <app-page
    ref="page"
    :toolbar="toolbar"
    :list="list"
    :creator="creator"
    :updater="updater"
  >
    <template v-if="this.canteenType !== 2" #actions="{ row }">
      <el-button type="text" @click="onUpdating(row)">
        编辑
      </el-button>
      <el-button type="text" @click="onRemoving(row)">
        删除
      </el-button>
    </template>
  </app-page>
</template>

<script>
import request from '@/utils/request';

export default {
  name: 'Group',
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        model: {
          name: undefined
        },
        fields: [
          {
            label: '分组名称',
            prop: 'name',
            placeholder: '请输入要搜索的分组名称'
          }
        ]
      },
      list: {
        mode: 'new',
        url: '/canteenConsumerGroup',
        fields: this.getFields()
      },
      creator: this.getCreator(),
      updater: this.getUpdater(),
      canteenType: Number(sessionStorage.getItem('canteenType'))
    };
  },
  methods: {
    getFields() {
      return [
        {
          label: '默认分组',
          prop: 'isDefault',
          width: 80,
          headerAlign: 'center',
          type: 'switch',
          activeValue: 1,
          inactiveValue: 0
        },
        {
          label: '分组名称',
          prop: 'name',
          width: 100,
          align: 'center',
          headerAlign: 'center'
        },
        {
          label: '早餐金额',
          prop: 'breakfast',
          width: 100,
          align: 'right',
          headerAlign: 'center',
          color: 'success',
          formatter(row) {
            return `￥${row.breakfast}`;
          }
        },
        {
          label: '中餐金额',
          prop: 'lunch',
          width: 100,
          align: 'right',
          headerAlign: 'center',
          color: 'danger',
          formatter(row) {
            return `￥${row.lunch}`;
          }
        },
        {
          label: '晚餐金额',
          prop: 'dinner',
          width: 100,
          align: 'right',
          headerAlign: 'center',
          color: 'warning',
          formatter(row) {
            return `￥${row.dinner}`;
          }
        },
        {
          label: '订餐权限',
          width: 200,
          align: 'center',
          headerAlign: 'center',
          formatter(row) {
            const a = row.breakfastOpen == 0 ? '早' : '';
            const b = row.lunchOpen == 0 ? '中' : '';
            const c = row.dinnerOpen == 0 ? '晚' : '';
            const text = [a, b, c].join('，');
            return `${text}`;
          }
        },
        { label: '备注', prop: 'remark', minWidth: 140, headerAlign: 'center' }
      ];
    },
     getCreator() {
      if (Number(sessionStorage.getItem('canteenType')) !== 2){
        return {
          width: '400px',
          title: '新增分组',
          text: '新增分组',
          size: 'small',
          labelWidth: '80px',
          submit: true,
          url: '/canteenConsumerGroup/create',
          model: {
            isDefault: false,
            name: undefined,
            breakfast: undefined,
            lunch: undefined,
            dinner: undefined,
            breakfastOpen: 0,
            lunchOpen: 0,
            dinnerOpen: 0,
            remark: undefined
          },
          rules: {
            isDefault: { required: true, message: '请选择是否默认分组' },
            name: { required: true, message: '请填写名称' },
            breakfast: { required: true, message: '请填写早餐金额' },
            lunch: { required: true, message: '请填写午餐金额' },
            dinner: { required: true, message: '请填写晚餐金额' },
            breakfastOpen: { required: true, message: '请选择早餐是否启用' },
            lunchOpen: { required: true, message: '请选择餐餐是否启用' },
            dinnerOpen: { required: true, message: '请选择晚餐是否启用' }
          },
          fields: [
            {
              label: '默认分组',
              prop: 'isDefault',
              type: 'switch',
              activeValue: 1,
              inactiveValue: 0
            },
            {
              label: '名称',
              prop: 'name'
            },
            {
              label: '早餐金额',
              prop: 'breakfast',
              type: 'input-number'
            },
            {
              label: '午餐金额',
              prop: 'lunch',
              type: 'input-number'
            },
            {
              label: '晚餐金额',
              prop: 'dinner',
              type: 'input-number'
            },
            {
              label: '早餐启用',
              prop: 'breakfastOpen',
              type: 'switch',
              activeValue: 0,
              inactiveValue: 1
            },
            {
              label: '午餐启用',
              prop: 'lunchOpen',
              type: 'switch',
              activeValue: 0,
              inactiveValue: 1
            },
            {
              label: '晚餐启用',
              prop: 'dinnerOpen',
              type: 'switch',
              activeValue: 0,
              inactiveValue: 1
            },
            {
              label: '备注',
              prop: 'remark',
              type: 'textarea'
            }
          ]
        };
      }else {
        return ''
      }
    },
    getUpdater() {
      const target = this.getCreator();
      return Object.assign(target, {
        text: '编辑分组',
        url: '/canteenConsumerGroup/update'
      });
    },
    onUpdating(row) {
      const { updater, $refs } = this;
      updater.model = row;
      $refs.page.onUpdating();
    },
    async onRemoving(row) {
      const { $confirm, $refs } = this;
      try {
        await $confirm('确认要删除分组吗?', '提示', {
          type: 'warning'
        });
        await request.post('/canteenConsumerGroup/remove', {
          id: row.id
        });
        await $refs.page.getItems();
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
